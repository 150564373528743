import { createContext, useContext, useEffect, useState } from "react";
import contacts from "data/contacts";
import { useSocketContext } from "./socketContext";
import axios from 'axios';

const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);

const UsersProvider = ({ children }) => {
	const socket = useSocketContext();

	const [users, setUsers] = useState([]);
	useEffect(() => {
		 async function fetchData() {
		  const data = await contacts();
		  setUsers(data.data);
		}
		 fetchData();
		
	  }, []);

	const _updateUserProp = (userId, prop, value) => {
		setUsers((users) => {
			const usersCopy = [...users];
			let userIndex = users.findIndex((user) => user.id === userId);
			const userObject = usersCopy[userIndex];
			usersCopy[userIndex] = { ...userObject, [prop]: value };
			return usersCopy;
		});
	};

	const setUserAsTyping = (data) => {
		const { userId } = data;
		_updateUserProp(userId, "typing", true);
	};

	const setUserAsNotTyping = (data) => {
		const { userId } = data;
		_updateUserProp(userId, "typing", false);
	};

	const fetchMessageResponse = (data) => {
		console.log('data from socket');
		console.log(data);
		setUsers((users) => {
			const { from_id, text } = data;
			let userIndex = users.findIndex((user) => user.phone_number === from_id);
			users.map((user)=>console.log(user.phone_number));
			const usersCopy = JSON.parse(JSON.stringify(users));
			const newMsgObject = {
				content: text,
				sender: from_id,
				time: new Date().toLocaleTimeString(),
				status: null,
			};
			const today = new Date();
			const todayDate = today.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric',
			year: 'numeric'
		  });

			// usersCopy[userIndex].messages.TODAY.push(newMsgObject);
			if (!usersCopy[userIndex].messages[todayDate]) {
				usersCopy[userIndex].messages[todayDate] = [];
			  }
			  usersCopy[userIndex].messages[todayDate].push(newMsgObject);
			return usersCopy;
		});
	};

	useEffect(() => {
		socket.on("message", fetchMessageResponse);/*fetch_response*/
		socket.on("start_typing", setUserAsTyping);
		socket.on("stop_typing", setUserAsNotTyping);
	}, [socket]);

	const setUserAsUnread = (userId) => {
		_updateUserProp(userId, "unread", 0);
	};

	const addNewMessage = (userId, message) => {
		let userIndex = users.findIndex((user) => user.id === userId);
		const usersCopy = [...users];
		const newMsgObject = {
			content: message,
			sender: null,
			time: new Date().toLocaleTimeString(),
			status: "delivered",
		};
		const today = new Date();

		const todayDate = today.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric',
			year: 'numeric'
		  });
		// usersCopy[userIndex].messages.TODAY.push(newMsgObject);
		// setUsers(usersCopy);
		const messages = usersCopy[userIndex].messages;

		if (messages[todayDate]) { // if today's messages already exist
		  messages[todayDate].push(newMsgObject); // add new message to the existing array
		} else { // if today's messages do not exist yet
		  messages[todayDate] = [newMsgObject]; // create a new array with today's message
		}
		setUsers(usersCopy);

		socket.emit("fetch_response", { userId });
	};

	return (
		<UsersContext.Provider value={{ users, setUserAsUnread, addNewMessage }}>
			{children}
		</UsersContext.Provider>
	);
};

export { useUsersContext, UsersProvider };
