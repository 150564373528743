import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import Header from "./components/Header";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "components/Icon";
import Search from "./components/Search";
import Profile from "./components/Profile";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";
import Sidebar from "../../components/Sidebar";
import axios from 'axios';

const Chat = ({ match, history }) => {
	const { users, setUserAsUnread, addNewMessage } = useUsersContext();

	const userId = match.params.id;
	let user = users.filter((user) => user.id === Number(userId))[0];

	const lastMsgRef = useRef(null);
	const [showAttach, setShowAttach] = useState(false);
	const [showEmojis, setShowEmojis] = useState(false);
	const [showProfileSidebar, setShowProfileSidebar] = useState(false);
	const [showSearchSidebar, setShowSearchSidebar] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [whatsappToken, setWhatsappToken] = useState('');

	let org_data;
	async function getToken() {
		try {
		  const response = await axios.post('https://whatsapp.shade6.com/api/whatsapp/getToken', {
			firstName: 'Fred',
		  }, {
			headers: {
			  'Content-Type': 'application/x-www-form-urlencoded',
			  "token":localStorage.getItem('token')
			}
		  });
		  return response.data.data[0].whatsapp_token;
		} catch (error) {
		  console.error('Error fetching token:', error);
		  return null;
		}
	  }
	  
	useEffect(() => {
		const fetchToken = async () => {
		  const token = await getToken();
		  if (token) {
			setWhatsappToken(token);
		  } else {
			// Handle error case
		  }
		};
	  
		fetchToken();
		
		if (!user) history.push("/");
		else {
		  scrollToLastMsg();
		  setUserAsUnread(user.id);
		}
	  }, []);

	useEffect(() => {
		user && scrollToLastMsg();
	}, [users]);

	const openSidebar = (cb) => {
		// close any open sidebar first
		setShowProfileSidebar(false);
		setShowSearchSidebar(false);

		// call callback fn
		cb(true);
	};

	const scrollToLastMsg = () => {
		lastMsgRef.current.scrollIntoView();
	};

	const submitNewMessage = async() => {

		if(newMessage)
		addNewMessage(user.id, newMessage);
		setNewMessage("");
		scrollToLastMsg();
		const res = await axios.post(
      'https://whatsapp.shade6.com/api/whatsapp/storeMsg',
      { name:user.name,
		phoneNumber:user.phone_number,
		text:newMessage
      },
      {
        headers: {
          token:
		  localStorage.getItem('token'),
        },
      }
    );
		const phoneId = "103482549066517"
		var data = JSON.stringify({
			"messaging_product": "whatsapp",
			"recipient_type": "individual",
			"to": `${user.phone_number}`,
			"type": "text",
			"text": {
			  "preview_url": false,
			  "body": `${newMessage}`
			}
		  });
		  
		  const config = {
			method: 'post',
			url: `https://graph.facebook.com/v15.0/${phoneId}/messages`,
			headers: { 
			  'Authorization': `Bearer ${whatsappToken}`,
			  'Content-Type': 'application/json'
			},
			data: data
		  };
		axios(config)
		.then(function (response) {
  		console.log(JSON.stringify(response.data));
		})
		
		.catch(function (error) {
  		console.log(error);
		});

		//console.log('hello');
	};

	return (
		<>
		<Sidebar/>
		<div className="chat">
			<div className="chat__body">
				<div className="chat__bg"></div>

				<Header
					user={user}
					openProfileSidebar={() => openSidebar(setShowProfileSidebar)}
					openSearchSidebar={() => openSidebar(setShowSearchSidebar)}
				/>
				<div className="chat__content">
					<Convo lastMsgRef={lastMsgRef} messages={user.messages} />
				</div>
				<footer className="chat__footer">
					<button
						className="chat__scroll-btn"
						aria-label="scroll down"
						onClick={scrollToLastMsg}
					>
						<Icon id="downArrow" />
					</button>
					<EmojiTray
						showEmojis={showEmojis}
						newMessage={newMessage}
						setNewMessage={setNewMessage}
					/>
					<ChatInput
						showEmojis={showEmojis}
						setShowEmojis={setShowEmojis}
						showAttach={showAttach}
						setShowAttach={setShowAttach}
						newMessage={newMessage}
						setNewMessage={setNewMessage}
						submitNewMessage={submitNewMessage}
					/>
				</footer>
			</div>
			<ChatSidebar
				heading="Search Messages"
				active={showSearchSidebar}
				closeSidebar={() => setShowSearchSidebar(false)}
			>
				<Search />
			</ChatSidebar>

			<ChatSidebar
				heading="Contact Info"
				active={showProfileSidebar}
				closeSidebar={() => setShowProfileSidebar(false)}>
				<Profile user={user} />
			</ChatSidebar>
		</div>
	</>
	);
};

export default Chat;
