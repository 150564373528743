import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Loader from './components/Loader';
import Home from './pages/Home';
import Sidebar from 'components/Sidebar';
import Chat from 'pages/Chat';
import Login from './pages/LogIn/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const userPrefersDark =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

  const PrivateRoute = ({ component: Component, ...rest }) =>{ 
    const isAuthenticated=!!localStorage.getItem('token')
    return (
    <Route
      {...rest}
      render={(props) =>
         isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )};
function App() {
  const [appLoaded, setAppLoaded] = useState(false);
  const [startLoadProgress, setStartLoadProgress] = useState(false);
  console.log(!!localStorage.getItem('token'))
  useEffect(() => {
    if (userPrefersDark) document.body.classList.add('dark-theme');
    stopLoad();
  }, []);

  const stopLoad = () => {
    setStartLoadProgress(true);
    setTimeout(() => setAppLoaded(true), 3000);
  };

  if (!appLoaded) return <Loader done={startLoadProgress} />;

  return (
     <div className="app">
      <ToastContainer />
      <p className="app__mobile-message">Only available on desktop 😊.</p>
      <Router>
        <div className="app-content">
          <Switch>
          <Route
              exact
              path="/login"
              render={() => (!!localStorage.getItem('token') ? <Redirect to="/home" /> : <Login />)}
            />
            <PrivateRoute
              path="/chat/:id"
              component={Chat}
            />
            <PrivateRoute path="/home" component={Home}/>
            {/* Redirect to login if no matching route */}
            <Redirect from="/" to={
              !!localStorage.getItem('token')?"/home":"/login"}
               />
             
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
