import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';

import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { toast } from 'react-toastify';
import spotCareLogo from '../../assets/images/sclogo.png';
import './Login.css';

function Login() {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  let decodedToken;
  const history = useHistory();
  //API'S
  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post('https://whatsapp.shade6.com/api/whatsapp/login', {
        userId: userId,
        password: password,
      })
      .then((result) => {
        let userData = result.data
        if (result.data.status) {
          // saving token in local storage
          localStorage.setItem('token', result.data.token);
          decodedToken = jwtDecode(result.data.token);
          //saving decoded token into session storage
          saveAccessTkn(decodedToken);
          toast.success('Success !', {
            position: toast.POSITION.TOP_CENTER,
          });     
          history.push('/home');
        } else {
          toast.error('Login Failed !', {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push('/login');
        }
      });
  };
  // functions
  const saveAccessTkn = (decodedToken) => {
    const currentUser = {
      data: decodedToken,
    };
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  };
  return (
    <div className="box">
      <div className="overlay">
        <div className="center-div">
          <p className="logintxt">Login</p>
          <br />

          <img className="logo" src={spotCareLogo} alt="spotcarelogo" />
          <p className="logoDesc">Powered By Klinik Everywhere</p>
          <div className="txtField">
            <TextField
              style={{ width: '300px' }}
              id="standard-basic"
              label="User ID"
              variant="standard"
              value={userId}
              onChange={(e) => {
                setUserId(e.target.value);
              }}
            />
            <br />
            <TextField
              style={{ width: '300px' }}
              id="standard-basic"
              label="Password"
              variant="standard"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <br />
            <br />
            <a href="http://" className="underline">
              Forget Password
            </a>
            <br />
          </div>
          <Button
            style={{ width: '150px' }}
            className="loginBtn"
            variant="contained"
            disabled={!userId || !password}
            onClick={handleLogin}
          >
            Log in
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
